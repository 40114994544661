<template>
  <div class="visitar mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Clientes por visitar</v-card-title>
          <v-card-subtitle>
            Visualiza tus clientes con saldo pendiente, que aún no cuentan con
            visitas programadas.
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-data-table
                  dense
                  :headers="headers"
                  :loading="loading_t"
                  :search="search"
                  :items="sortClientes"
                  hide-default-footer
                  :items-per-page="perPage"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                >
                  <template v-slot:top>
                    <v-row dense>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field
                          outlined
                          rounded
                          dense
                          append-icon="mdi-magnify"
                          label="Buscar"
                          v-model="search"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn
                          dark
                          color="black"
                          @click="download"
                          :loading="loading"
                          >Descargar</v-btn
                        >
                      </v-col>
                      <v-col cols="12" md="4" sm="4"
                        ><div style="display: flex">
                          <v-subheader> Clientes X pag </v-subheader>
                          <div style="width: 120px">
                            <v-select
                              :items="itemsPerPage"
                              v-model="perPage"
                              outlined
                              dense
                            ></v-select>
                          </div></div
                      ></v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-pagination
                          dense
                          v-model="page"
                          :length="pageCount"
                          :total-visible="6"
                        ></v-pagination>
                      </v-col>
                      <v-col cols="12" md="6">
                        <h3>Total clientes: {{ clientes.length }}</h3>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item.saldo="{ item }">
                    {{ item.saldo | currency }}
                  </template>
                  <template v-slot:item.visita="{ item }">
                    <span v-if="item.visita">
                      {{ moment(item.visita).format("ll") | currency }}
                    </span>
                    <span v-else style="color: red">Sin visitas agendadas</span>
                  </template>
                  <template v-slot:body.append>
                    <tr>
                      <th colspan="4">Totales</th>
                      <th colspan="2">
                        {{ totalizacion | currency }}
                      </th>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import XLSX from "xlsx";
import * as moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    loading_t: false,
    loading: false,
    search: "",
    search_detalle: "",
    clientes: [],
    perPage: 25,
    page: 1,
    pageCount: 0,
    itemsPerPage: [
      {
        text: 10,
        value: 10,
      },
      {
        text: 25,
        value: 25,
      },
      {
        text: 50,
        value: 50,
      },
      {
        text: 100,
        value: 100,
      },
      {
        text: 500,
        value: 500,
      },
    ],
    headers: [
      {
        align: "start",
        text: "CC / ID",
        value: "cedula",
      },
      {
        text: "Cliente",
        value: "nombre",
      },
      {
        text: "Telefono",
        value: "telefono",
      },
      {
        text: "Dirección",
        value: "direccion",
      },
      {
        text: "Saldo",
        value: "saldo",
        sortable: false,
        filterable: false,
      },
      {
        text: "Ultima visita",
        value: "visita",
        sortable: false,
        filterable: false,
      },
    ],
  }),
  methods: {
    loadVisitas() {
      this.loading_t = true;
      const body = {
        route: "/visitasPendientes",
        params: {
          date: moment().format("YYYY-MM-DD"),
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    download() {
      this.loading = true;
      const data = this.sortClientes.map((cliente) => {
        return {
          "Cc/ID": cliente.cedula,
          Cliente: cliente.nombre,
          Telefono: cliente.telefono,
          Saldo: cliente.saldo,
          Direccion: cliente.direccion,
          "Ultima visita": cliente.visita
            ? moment(cliente.visita).format("ll")
            : "Sin visitas agendadas",
        };
      });

      const filename = "Clientes por visitar  ";
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
      this.loading = false;
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadVisitas();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    sortClientes() {
      return this.clientes.sort((a, b) => {
        return b.saldo - a.saldo;
      });
    },
    totalizacion() {
      return this.clientes.reduce((index, item) => {
        return index + parseFloat(item.saldo);
      }, 0);
    },
  },
};
</script>
